import { DashboardRoutes, ModuleRoutes, SystemRoutes } from '@nomadgcs/ntc-common-kt'
import { System } from 'generated/module/system/System'
import { ModuleCategory } from 'generated/module/ModuleCategory'
import { Module } from 'generated/module/Module'
import { Dashboard } from 'generated/dashboard/Dashboard'
import { HTTPContentType, HTTPMethod } from './HttpType'

export async function requestEndpoint<TRequest, TResponse>(
    endpoint: string,
    request: TRequest,
    method: HTTPMethod,
    controller?: AbortController
): Promise<TResponse> {
    const options: RequestInit = {
        method,
        signal: controller?.signal
    }
    const headers = new Headers()
    headers.append('Authorization', 'token')

    if (method !== HTTPMethod.GET) {
        if (!(request instanceof FormData)) {
            headers.append('Content-Type', `${HTTPContentType.APPLICATION_JSON}; charset=UTF-8`)
            options.body = JSON.stringify(request)
        } else {
            // Handle FormData request without stringifying
            options.body = request
        }
    }
    options.headers = headers

    const response = await fetch(endpoint, options)

    if (!response.ok) {
        if (response.status === 401) {
            clearSessionStorage()
            window.location.reload()
        } else {
            response["message"] = response.statusText
            response["code"] = response.status
            throw response
        }
    } else {
        if (response.redirected) {
            // Redirect on password change running infinite loop, to get rid of that clear the session data
            // Other work around is on self password change, get the new session token which should set into cache
            clearSessionStorage()
            document.location = response.url
        }

        // Check if response is JSON or text
        const contentType = response.headers.get('Content-Type') || ''
        if (contentType.includes(HTTPContentType.APPLICATION_JSON)) {
            return await response.json() as TResponse
        } else {
            return await response.text() as TResponse
        }
    }
}

/**
 * Get the list of modules
 * @param data
 * @param controller
 */
export async function fetchModules(controller?: AbortController) {
    const endpoint = ModuleRoutes.modules

    return await requestEndpoint<any, Module[]>(
        endpoint, {}, HTTPMethod.GET, controller
    )
}

/**
 * Get the list of modules based on moduleId
 * @param moduleIds
 * @param controller
 */
export async function fetchModulesById(moduleIds: string[], controller?: AbortController) {
    const endpoint = ModuleRoutes.modulesById(moduleIds)

    return await requestEndpoint<any, Module[]>(
        endpoint, moduleIds, HTTPMethod.GET, controller
    )
}

/**
 * Get the list of modules based on category
 * @param category
 * @param controller
 */
export async function fetchModulesByCategory(category: ModuleCategory, controller?: AbortController) {
    const endpoint = ModuleRoutes.modulesByCategory(category)

    return await requestEndpoint<any, Module[]>(
        endpoint, category, HTTPMethod.GET, controller
    )
}

/**
 * Get the list of systems
 * @param data
 * @param controller
 */
export async function fetchSystems(controller?: AbortController) {
    const endpoint = SystemRoutes.systems

    return await requestEndpoint<any, Module[]>(
        endpoint, null, HTTPMethod.GET, controller
    )
}

/**
 * Get the list of systems based on systemsId
 * @param systemIds
 * @param controller
 */
export async function fetchSystemsById<T extends System>(systemIds: string[], controller?: AbortController) {
    const endpoint = SystemRoutes.systemsById(systemIds)
    const systems = await requestEndpoint<string[], System[]>(
        endpoint, systemIds, HTTPMethod.GET, controller
    )

    return systems.reduce((mapped: { [key in string]: T }, system: T) => {
        mapped[system.id] = system
        return mapped
    }, {})
}

/**
 * Submit the module config basic information to update the entry
 * @param endpoint
 * @param updateValue
 * @param controller
 */
export async function updateModuleConfig(endpoint: string, updateValue: any, controller?: AbortController) {
    const endpointUrl = endpoint

    return await requestEndpoint<any, any>(
        endpointUrl, updateValue, HTTPMethod.PUT, controller
    )
}

/**
 * Submit the system state information to update the entry
 * @param endpoint
 * @param systems
 * @param setSystems
 * @param system
 * @param updateValue
 * @param update
 * @param controller
 */
export async function updateSystemState<T extends System, U>(
    endpoint: string,
    systems: { [key in string]: T },
    setSystems: (systems: { [key in string]: T }) => void,
    system: T, updateValue: U, update: (system: T, value: U) => void, controller?: AbortController) {

    // Update the DOM and whatever else
    update(system, updateValue)
    const updatesSystems = { ...systems }
    updatesSystems[system.id] = system
    setSystems(updatesSystems)
    const endpointUrl = endpoint

    return await requestEndpoint<any, any>(
        endpointUrl, updateValue, HTTPMethod.PUT, controller
    )
}

/**
 * Stop system
 * @param system
 * @param controller
 */
export async function stopSystem(system: System) {
    const endpoint = SystemRoutes.stop(system.id)

    return await requestEndpoint<any, any>(
        endpoint, system, HTTPMethod.PUT, null
    )
}

/**
 * Get the list of dashboard based on id
 * @param id
 * @param controller
 */
export async function fetchDashboardById(id: string, controller?: AbortController) {
    const endpoint = DashboardRoutes.dashboard(id)

    return await requestEndpoint<any, Dashboard>(
        endpoint, id, HTTPMethod.GET, controller
    )
}

/**
 * Submit the all system state information to update the entry
 * @param endpoint
 * @param systems
 * @param setSystems
 * @param updateValue
 * @param update
 * @param controller
 */
export async function updateAllSystemStates<T extends System, U>(
    endpoint: (systemId: string) => string,
    systems: { [key in string]: T },
    setSystems: (systems: { [key in string]: T }) => void,
    updatedValue: U, update: (system: T, value: U) => void, controller?: AbortController) {

    // Update the DOM and whatever else
    const updatesSystems = { ...systems }
    Object.values(systems).forEach(system => {
        update(system, updatedValue)
        updatesSystems[system.id] = system
    })

    setSystems(updatesSystems)

    Object.values(systems).forEach(system => {
        const addr = endpoint(system.id)
        const endpointUrl = addr
        return requestEndpoint<any, any>(
            endpointUrl, updatedValue, HTTPMethod.PUT, controller
        )
    })
}

export function stopAllSystems(systems: { [key in string]: System }) {
    Object.values(systems).forEach(stopSystem)
}

export function clearSessionStorage() {
    sessionStorage.clear()
}

