import { createSlice } from '@reduxjs/toolkit'

export const booleanSlice = createSlice({
    name: 'drawerToggle',
    initialState: {
        value: true
    },
    reducers: {
        toggle: (state) => {
            state.value = !state.value
            localStorage.setItem('drawerTracker', state.value)
        }
    }
})
// Action creators are generated for each case reducer function
export const { toggle } = booleanSlice.actions
export default booleanSlice.reducer
