import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './booleanSlice'
import themeReducer from './themeSlice'

export default configureStore({
    reducer: {
        drawerTracker: counterReducer,
        themeTracker: themeReducer
    }
})
