import React from 'react'
import ReactDOM from 'react-dom/client'
import store from './redux/store'
import { Provider } from 'react-redux'
import { EnvRoutes } from '@nomadgcs/ntc-common-kt'
import './index.css'
import { noRedirectApi } from './api/ApiConfig'

async function importAppEnvironment () {
    const response = await noRedirectApi.get(EnvRoutes.env('PLATFORM'))
    const environment = response.data?.toLowerCase()
    localStorage.setItem('environment', environment)
    console.info(`Running as ${environment} environment`)
    switch (environment) {
        case 'asset':
            return import('./asset/AssetApp.js')
        case 'portal':
            return import('./portal/PortalApp.js')
        default:
            return Promise.reject(new Error(`unknown build target: ${response}`))
    }
}

importAppEnvironment().then(({ default: Environment }) =>
    ReactDOM.createRoot(document.getElementById('root')).render(
        <Provider store={store}>
            <Environment/>
        </Provider>
    )
)
