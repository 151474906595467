import axios, { HttpStatusCode } from 'axios'
import { clearSessionStorage } from '../utils/HTTPUtils'

export const api = axios.create({
    headers: {
        'Content-Type': 'application/json; charset=UTF-8'
    }
})

export const noRedirectApi = axios.create({
    headers: {
        'Content-Type': 'application/json; charset=UTF-8'
    }
})

api.interceptors.response.use(r => r, handleErrorResponses)

function handleErrorResponses(e: any) {
    if (e.response) {
        switch (e.response.code) {
            case HttpStatusCode.Found:
                window.location.replace(e.response.url)
                break
            case HttpStatusCode.Unauthorized:
                // NOTE: don't redirect to /login as it would override a node
                clearSessionStorage()
                window.location.reload()
                break
            default:
                console.error(e.message)
                break
        }
    } else {
        console.error(e.message)
    }
}
